import { Navigate, Outlet } from 'react-router-dom';
import {useAppDispatch, useAppSelector} from './redux/hooks';
import { useEffect, useState } from 'react';
import * as AuthActions from './redux/slices/auth.slice';

interface IProps {
  isFirebaseInitialized:boolean
}

const ProtectedRoute = (props:IProps) => {
  const { isLoggedIn } = useAppSelector((state) => state.auth);
  const [isCheckingLoginStatus, setIsCheckinLoginStatus] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  
  useEffect(()=>{
    if(props.isFirebaseInitialized){
      dispatch(AuthActions.isLoggedIn());
      setIsCheckinLoginStatus(false);
    }
  },[props.isFirebaseInitialized])

  if(isCheckingLoginStatus){
    return (
      <p>Loading...</p>
    )
  }
  else if (!isLoggedIn) {
    return (
      <Navigate to={"/login"} />
    )
  }

  return <Outlet />
}
export default ProtectedRoute